<template>
    <v-autocomplete class="category-group"
                    v-model="selecteds"
                    :items="categories"
                    item-text="title"
                    return-object
                    @change="handleChange"
                    color="#2F80ED"
                    outlined
                    filled
                    chips
                    solo
                    flat
                    deletable-chips
                    multiple
                    no-data-text="Não há categorias"
                    column>
        <template v-slot:selection="data">
            <v-chip close
                    color="#2F80ED"
                    text-color="#FFF"
                    @click:close="handleClose(data.item.title)"
                    @click="handleClose(data.item.title)">
                {{data.item.title}}
            </v-chip>
        </template>
    </v-autocomplete>
</template>

<script>

    export default {
        name: "CategoriesGroup",

        data: () => ({
            selecteds: [],
        }),

        props: {
            pSelecteds: Array,
            categories: Array,
            value: Array,
        },

        methods: {
            handleClose: function (title) {
                const index = (this.selecteds.indexOf(this.selecteds.find(item => item.title == title)))
                if (index >= 0) this.selecteds.splice(index, 1)
                this.handleChange();
            },

            handleChange: function () {
                this.$emit('input',this.selecteds)
            }
        },

        created() {
            this.selecteds = [ ...this.pSelecteds ] || [];
        }
    };
</script>

<style >
    .category-group .v-chip {
        margin-bottom: 8px !important;
        margin-right: 8px !important;
        padding: 10px 15px !important;
        font-size: 1.125rem !important;
        font-weight: 400;
        border-radius: 10px !important;
        border: 1px solid #2F80ED;
        height: initial !important;
        white-space: normal;
    }

    .category-group .v-chip:before {
        border-radius: 0;
    }

    .v-chip--outlined {
        border: 2px solid #2F80ED !important;
    }

    .v-text-field--outlined fieldset {
        top: 0 !important;
        background: #F8F8F8 !important;
        border: 0.063rem solid #BBB !important;
    }

    .v-select__slot {
        padding-top: 8px;
        padding-bottom: 6px;
    }

    .v-autocomplete__content .v-list .v-list-item, .v-autocomplete__content .v-list .v-list-item--active i {
        color: #2F80ED !important;
    }
</style>